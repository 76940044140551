<template lang="pug">
.row.new-spr-row
  .col-4.spr-domain
    om-select#new-spr-domain(
      v-model="selectedDomain"
      :options="domainOptions"
      searchable
      :placeholder="$t('SPR.new.domain.placeholder')"
      :error="$v.selectedDomain.$error"
    )
  .col-2.spr-button
    om-button#new-spr(primary @click="addNew" :loading="saving") {{ $t('SPR.new.actionButton') }}
</template>
<script>
  import { mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import ADD_SPR_CONFIG from '@/graphql/AddSPRConfig.gql';

  export default {
    data: () => ({
      selectedDomain: null,
      saving: false,
    }),
    computed: {
      ...mapGetters(['domains']),
      domainOptions() {
        return this.domains
          .map((domain) => ({
            key: domain._id,
            value: domain.domain,
          }))
          .filter(({ domain }) => domain !== '*');
      },
    },
    methods: {
      async addNew() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        this.saving = true;

        let success;
        let errorMessage;
        try {
          const response = await this.$apollo.mutate({
            mutation: ADD_SPR_CONFIG,
            variables: {
              sprConfig: {
                domainId: this.selectedDomain.key,
              },
            },
          });

          success = !!response?.data?.result?.success;
          errorMessage = response?.data?.result?.message ?? null;

          if (!success && response?.errors?.length) {
            console.error('Error during add SPR config', { errors: response.errors });
          }
        } catch (err) {
          console.error('Caugth error during add SPR config', {
            msg: err.message,
            stack: err.stack,
          });
        }

        if (success) {
          this.reset();
          this.$notify({
            type: 'success',
            text: this.$t('SPR.new.success'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: !errorMessage ? this.$t('SPR.new.failed') : errorMessage,
          });
        }
        this.saving = false;
        this.$v.$reset();
        this.$bus.$emit('reloadSPRConfigList');
      },
      reset() {
        this.selectedDomain = null;
        this.url = '';
      },
    },
    validations: {
      selectedDomain: {
        required,
      },
    },
  };
</script>
